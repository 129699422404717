export default {
  namespaced: true,
  state: {
    userLocation: {
      lat: null,
      lng: null,
    },
    address: "",
  },

  getters: {
    get_res_location: (state) => state.userLocation,
    get_res_address: (state) => state.address,
  },

  mutations: {
    setLocation(state, userLocation) {
      state.userLocation = userLocation;
    },
    setAddress(state, address) {
      state.address = address;
    },
  },
  actions: {
    updateLocation({ commit }, loc) {
      commit("setLocation", loc);
    },
    updateAddress({ commit }, address) {
      commit("setAddress", address);
    },
  },
};
