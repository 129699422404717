import axios from "axios";

const notifications = async (token, memberid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "get-list-notif/" + memberid, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const readNotif = async (token, notifid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "read-notif-user/" + notifid, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default {
  notifications,
  readNotif,
};
