import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: "pusher",
    key: "c6ea6365a3591f77e88a",
    cluster: "ap1",
    forceTLS: true,
});

export default echo;