import axios from "axios";

const userDetail = async (token) => {
  return await axios.post(
    process.env.VUE_APP_GATEWAY + "detail-user",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const ListPasien = async (token, userId) => {
  let data = new FormData();
  data.append("user_id", userId);
  return await axios.post(process.env.VUE_APP_GATEWAY + "list-user-pasien", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const StorePasienUser = async (token, userId, form) => {
  let data = new FormData();
  data.append("user_id", userId);
  data.append("name", form.name);
  data.append("gender", form.gender);
  data.append("date_of_birth", form.dob);
  data.append("province", form.provinsi);
  data.append("city", form.kabupaten);
  data.append("kecamatan", form.kecamatan);
  data.append("kelurahan", form.kelurahan);
  data.append("address", form.alamat);
  data.append("nik", form.nik);
  data.append("relationship", form.relation);
  return await axios.post(process.env.VUE_APP_GATEWAY + "store-user-patient", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const StorePasienByMr = async (token, user_id, no_mr, dob) => {
  let data = new FormData();
  data.append("user_id", user_id);
  data.append("no_mr", no_mr);
  data.append("date_of_birth", dob);
  return await axios.post(process.env.VUE_APP_GATEWAY + "store-patient-by-mr", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const logout = async (token) => {
  return await axios.post(
    process.env.VUE_APP_GATEWAY + "logout-account",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export default {
  userDetail,
  ListPasien,
  logout,
  StorePasienUser,
  StorePasienByMr,
};
