<template>
  <div id="app">
    <SplashScreen v-if="showSplashScreen" />
    <router-view v-else />
    <AppFooter v-if="!showSplashScreen && !hideFooter" />
  </div>
</template>

<script>
import AppFooter from "@/components/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      showSplashScreen: true,
      hideFooter: false,
    };
  },
  watch: {
    $route(to) {
      this.checkLoginRoute(to);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showSplashScreen = false;
      this.checkLoginRoute(this.$route);
    }, 3000);
  },
  methods: {
    checkLoginRoute(route) {
      if (route.path == "/login" || route.path == "/register") {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
    },
  },
  components: {
    AppFooter,
  },
};
</script>
