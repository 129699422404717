<template>
  <div class="container container-footer">
    <footer class="footer">
      <div class="footer-item" :class="{ active: isActive('HomeView') }" @click="$router.push({ name: 'HomeView' })">
        <i class="material-icons">home</i>
        <span>Home</span>
      </div>
      <div class="footer-item" :class="{ active: isActive('history-view') }" @click="$router.push({ name: 'history-view' })">
        <i class="material-icons">receipt</i>
        <span>Pemesanan</span>
      </div>
      <div class="footer-item emergency" :class="{ active: isActive('history-view') }" @click="redirectToWhatsApp()">
        <i class="material-icons">call</i>
        <span>Panggilan Darurat</span>
      </div>
      <div class="footer-item" :class="{ active: isActive('notification-view') }" @click="$router.push({ name: 'notification-view' })">
        <i class="material-icons">message</i>
        <span>Notifikasi</span>
        <span v-if="unreadNotifications > 0" class="notification-badge">{{ unreadNotifications }}</span>
      </div>
      <div class="footer-item" :class="{ active: isActive('profile-view') }" @click="$router.push({ name: 'profile-view' })">
        <i class="material-icons">person</i>
        <span>Akun</span>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import echo from "@/controllers/echo";
import storage from "@/controllers/storage";

export default {
  name: "AppFooter",
  data() {
    return {
      notifications: [],
      session: {},
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.token) {
      let data_req = {
        token: this.session.token,
        memberId: this.session.memberId,
      };
      await this.fetchDataNotif(data_req);

      echo.channel(`USR-RK.${this.session.memberId}`).listen("NotificationUserRK", (data) => {
        if (data) {
          let data_req = {
            token: this.session.token,
            memberId: this.session.memberId,
          };
          this.fetchDataNotif(data_req);
        }
      });
    }
  },
  methods: {
    isActive(routeName) {
      return this.$route.name === routeName;
    },

    redirectToWhatsApp() {
      const formattedNumber = "+6285320002020";
      const encodedPhoneNumber = encodeURIComponent(formattedNumber);
      const whatsappURL = `https://wa.me/${encodedPhoneNumber}`;
      window.open(whatsappURL, "_blank");
    },

    ...mapActions({
      fetchDataNotif: "user/fetchNotif",
    }),

    ...mapMutations({
      setNotif: "user/setNotif",
    }),
  },
  computed: {
    unreadNotifications() {
      return this.dataNotif.filter((notification) => notification.is_read == 0).length;
    },

    ...mapGetters("user", {
      dataNotif: "get_res_notif",
    }),
  },
};
</script>
