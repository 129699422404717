import Vuex from "vuex";
import location from "./modules/location";
import user from "./modules/user";

export default new Vuex.Store({
  modules: {
    location,
    user,
  },
  state: {
    // = data
  },

  getters: {
    // = computed properties.
  },

  actions: {
    // action
  },

  mutations: {},
});
